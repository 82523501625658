import React,{useState} from 'react'
import {RiDeleteBinLine} from 'react-icons/ri'
import {  MwSwitch } from '../ui';
import {BiEdit} from 'react-icons/bi'

import { usePut } from '../../hooks';

const PaymentMethodListItem = ({item,description,index,currentLangId ,confirmDeleteHandler,editHandler}) => {
    console.log(`${index}`  ,description,item)
    const [isActive,setIsActive] = useState( description.payment_method_id === item.id ? item.is_active : false);

    const {data:dataUpdate,loading:loadingUpdate,putData:putData} = usePut();

    const onChangeSwitch = (item) => {
        console.log(item);
        const _data = {
            "isActive" : !isActive,
        }

        putData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/payment-methods/update/${item.id}`,_data)
    } 

    return (
        <div key={index} className={`w-full`}>
                {description?.lang === currentLangId && 
                <div key={index} className={`flex flex-col  text-xs mt-3  ${ index > 0 ? `border-b` : ``}`}>
                    <div className='flex justify-between items-center'>
                        <div>{description?.name}</div>
                        <div className='flex flex-col items-center gap-2'>
                            <MwSwitch onChangeSwitch = {()=>onChangeSwitch(item)}  custKey={item?.id} isActive ={isActive} setIsActive ={setIsActive} />
                            
                        </div>
                    </div>
                    <div className='flex justify-between  text-slate-500' >
                        <div>{description?.desc}</div>
                        <div className='flex   pt-2'>
                            <BiEdit onClick={ () => editHandler(item)} size={14} className='my-2' />
                            <RiDeleteBinLine onClick={ () => confirmDeleteHandler(item)} size={14} className='m-2'/>
                        </div>
                        </div>
                    
                </div>}
            </div>
    )
}

export default PaymentMethodListItem