import PaymentMethodListItem from "./PaymentMethodListItem";


const PaymentMethodList = ({data,loading,currentLangId ,confirmDeleteHandler,editHandler}) => {


   

    const list = data.map((item,index) => {
        return item?.description?.map((description,index) => (
            <PaymentMethodListItem
                item = {item}
                description = {description}
                index = {index}
                currentLangId ={currentLangId}
                confirmDeleteHandler ={confirmDeleteHandler}
                editHandler ={editHandler}
                />
        ))
    });

    return (
        <div>
            
            <div className='text-sm text-slate-500 my-3 px-1'>Payment Method List</div>
            <div className='p-3  rounded-lg border border-slate-200'>{list}</div>
        </div>
    );
}

export default PaymentMethodList