import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { MwSpinnerButton, NoDataFound } from '../ui'
import { useInvoice } from '../../hooks';

const InvListBasic = ({
    data,
    loading=true,
    links=[],
    paginateHandler = (url)=>{} ,
    withBorder=true,
    withSummary=false,
}) => {
    const params = useParams();
    const {formatter} = useInvoice();
    return (
        <>
            {!loading ? 
            <div className={`flex-1 ${ withBorder && ' border rounded-xl ' }` }>
                { data.length > 0 ? <ul>
                    <li className='flex justify-between p-3 text-xs border-b bg-slate-100'>
                        <div className='w-24 px-1 text-center'>رقم العرض </div>
                        <div className='flex-1'>اسم العميل</div>
                        <div className='w-28 px-1  text-center'>المبلغ </div>
                        <div className='w-24 px-1  text-center'>التاريخ </div>
                    </li>
                    {data.map((result,index) => (
                        <div key={result.id}>
                        <Link  to={`/${params.tenant}/quotes/show/${result.id}/${result.company_id}/${result.branch_id}`} key={result.id}>
                            <li className={` flex justify-between p-2 text-xs border-b hover:bg-indigo-50 hover:font-bold  ${ data?.length !== index+1 && ' border-b' } `}>
                                <div className='w-24 px-1  text-center'>{result.inv_no}</div>
                                <div className='flex-1'>{result.customer_name || 'عميل عام'}</div>
                                <div className='w-28 px-1  text-center'>{ formatter.format(result.net_amount)} </div>
                                <div className='w-24 px-1  text-center'>{result.inv_date.substring(0,10)}</div>
                            </li>
                        </Link>
                        </div>
                    ))}
                </ul> : <NoDataFound msg={' لا توجد عروض اسعار'} /> }
            </div> : <div className='flex-1'><MwSpinnerButton withLabel={false} isFullCenter={true} /> </div> }
        </>
    )
}

export default InvListBasic