import React from 'react'
import {  useTranslation } from 'react-i18next';
import {MwInputText, MwTextArea} from '../../ui/';
import CardAmount from '../../ui/CardAmount';
import PromoCodeBox from '../PromoCodeBox';
import TagList from '../../ui/TagList';
import {amountFormat} from '../../../utils/invoceHelper';

const InvFormFooter = ({
    invNotes,
    setInvNotes,
    invTotalAmount,
    totalAmountWithDiscount,
    promoCode,
    setPromoCode,
    discount,
    vat,
    netTotal,
    havePromoCode,
    setHavePromoCode,
    promoCodeHandler,
    discountOnchange,
    SETTING_PRICE_INCLUDE_VAT,
    SETTING_INV_CURRENCY,
    tagList,
    setTagList,
    disabledDiscount,
    formatter
}) => {
    const [t] = useTranslation('global')
    console.log('footer => totalAmountWithDiscount',totalAmountWithDiscount)
    return (
        <div id='footer-inv' className='py-2' >
                    <MwTextArea 
                        placeholder={t('invoice.enterNotes')}
                        value={invNotes}
                        onChange={(e)=>setInvNotes(e.target.value)}
                        />
                    <div  className='flex md:flex-row flex-col justify-between '>

                        <TagList
                            tagList = {tagList} 
                            setTagList = {setTagList}
                        />
                        <PromoCodeBox
                            havePromoCode = {havePromoCode}
                            setHavePromoCode = {setHavePromoCode}
                            promoCode = {promoCode}
                            setPromoCode = {setPromoCode}
                            promoCodeHandler = {promoCodeHandler}
                            />
                    </div>
                    <hr className='my-2'/>

                    <div className='flex md:flex-row flex-col gap-2  items-top p-3 justify-between text-sm'>
                        <div className="flex-1  flex items-top justify-between">
                            <CardAmount
                                label= 'الإجمالي قبل الخصم'
                                amountValue={formatter.format(amountFormat(invTotalAmount || 0))}
                                //footerValue={formatter.format(SETTING_INV_CURRENCY)}
                                />
                            <div id='discount-card' className=' flex-1 text-center '>
                                <div className=' text-slate-400 text-xs'> الخصم علي إجمالي الفاتورة </div>
                                <MwInputText
                                    value={discount}
                                    disabled={disabledDiscount}
                                    // placeholder={t('invoice.discount')}
                                    classNameInput = 'font-bold py-5'
                                    size='sm'
                                    onChange={(e)=>discountOnchange(e.target.value)}
                                />
                                {/* <div className='text-slate-400 text-xs'>{ discount && SETTING_INV_CURRENCY.toUpperCase() }</div> */}
                            </div>
                            <CardAmount
                                label= 'الإجمالي بعد الخصم'
                                amountValue={ formatter.format(amountFormat(totalAmountWithDiscount || 0)) }
                                // footerValue={SETTING_INV_CURRENCY.toUpperCase()}
                                />
                        </div>
                        <div className="flex-1 flex items-center justify-between">
                            <CardAmount
                                label={t('invoice.vatAmount')}
                                amountValue={formatter.format(amountFormat(vat || 0))}
                                // footerValue={SETTING_INV_CURRENCY.toUpperCase()}
                                />
                            <CardAmount
                                label={t('invoice.netTotal')}
                                amountValue={formatter.format(amountFormat(netTotal || 0))}
                                // footerValue={SETTING_INV_CURRENCY.toUpperCase()}
                                />                        
                        </div>
                    </div>
                    <hr  />
                    <div className='pt-2 flex flex-col justify-center items-center'>
                        { SETTING_PRICE_INCLUDE_VAT === 'true' ?
                            <div className='flex justify-center items-center text-xs text-slate-400 '>{t('invoice.priceIncludesVat')}</div> : 
                            <div className='text-xs text-slate-400 text-center'>{t('invoice.priceNotIncludesVat')}</div> 
                            
                        }
                    </div>
                </div>
    )
}

export default InvFormFooter