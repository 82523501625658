import React from 'react'
import { MwButton } from '../../ui'

const WarningModal = ({warningList,onHide}) => {
    return (
        <div>
            <div className='flex gap-3  border-b p-3  bg-slate-200'>
                <div className='w-28 text-center'>CATEGORY</div>
                <div className='w-28 text-center'>CODE</div>
                <div className='flex-1 text-end'>MESSAGE</div>
                <div className='w-28 text-center'>TYPE</div>
            </div>
            {
                warningList?.map((warn) => (
                <div className='flex gap-3 p-2 border-b py-2'>
                    <div  className='w-28 text-center'>{warn.category}</div>
                    <div  className='w-28 text-center'>{warn.code}</div>
                    <div className='flex-1 text-end'>{warn.message}</div>
                    <div  className='w-28 text-center'>{warn.type}</div>
                </div>
                ))
            }
            <div className='flex justify-end p-3'><MwButton size='sm' type='secondary' onClick={()=>onHide()} >Close</MwButton></div>
        </div>
    )
}

export default WarningModal