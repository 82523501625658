import {useEffect, useState} from 'react'
import { MwButton} from '../../ui';
import { useCurrent, usePost } from '../../../hooks';
import {langObjType,objLangTextHandler} from '../../../utils/global'
import CardHeadeWithActions from '../../ui/CardHeadeWithActions';
import GroupForm from './GroupForm';

const CreateGroup = ({setMode}) => {
    const {data:postResult,postData} = usePost();
    const {currentLangList,currentTenantId,currentCompanyId,currentBranchId,currentLangId} = useCurrent();
    const [formLang,setFormLang] = useState(currentLangId);
    const [errors,setErrors] = useState({});

    // Form state
    const [groupName,setGroupName] = useState(langObjType());
    const [groupDesc,setGroupDesc] = useState(langObjType());
    const [isActive,setIsActive] = useState(true);

    const formValidate = () => {
        let errorObj = {};
        setErrors('');
        if(!groupName[currentLangId]){
            errorObj = { ...errorObj, [`groupName.${currentLangId}`] : 'يجب ادخال اسم الجموعة'};
            setFormLang(currentLangId)
        }

        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            return true
        }

        return false;
    }
    const submitHandler = () => {
        if(formValidate()) return ;

        const _data = {
            groupName: objLangTextHandler(groupName),
            groupDesc: objLangTextHandler(groupDesc),
            isActive,
            tenantId:currentTenantId,
            companyId:currentCompanyId,
            branchId:currentBranchId
        }

        postData(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/groups`,_data);
    }

    useEffect(()=>{
        if(postResult){
            setMode('List')
        }
    },[postResult]);
    
    return (
        <>
            <CardHeadeWithActions title={`أضف مجموعة جديدة`}>
                <MwButton inGroup={true} type='cancelBtn' onClick={() => setMode('List')}  >
                    ادارة مجموعات العملاء
                </MwButton>
                <MwButton type="saveBtn" onClick={submitHandler}  inGroup={true} >حفظ</MwButton>
            </CardHeadeWithActions>
            <GroupForm
                    currentLangList = {currentLangList}
                    formLang = {formLang}
                    setFormLang = {setFormLang}
                    errors = {errors}
                    groupName = {groupName}
                    setGroupName =  {setGroupName}
                    groupDesc = {groupDesc}
                    setGroupDesc = {setGroupDesc}
                    isActive = {isActive}
                    setIsActive = {setIsActive}
                />
        </>
    );

}

export default CreateGroup