import React from 'react'
import {  useTranslation } from 'react-i18next';

const InvoiceBody = ({
    customerName,
    customer,
    salesmanName,
    invProducts,
    isDelivery,
    SETTING_INV_CURRENCY,
    SETTING_INV_VAT_PERCENT,
    formatter
}) => {
    console.log('invoice invProducts',invProducts);
    console.log('invoice customer',customer);

    const [t] = useTranslation('global')
    const invProductsList = invProducts.map((prod) => (
        <div key={prod.id} className={`flex justify-between border-b  ${ prod.is_returned && 'bg-orange-50'}`}>
            <div className='flex-1 p-2 border-e'>{prod.product_name} {prod.is_returned && <span className='font-bold text-orange-300'>[مرتجع]</span> } </div>
            {!isDelivery && <div className='w-20 text-center  border-e p-2'>{formatter.format(prod.price).slice(0,-6)} </div>}
            <div className='w-16 text-center border-e p-2'>{prod.qty}</div>
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.total_price).slice(0,-6)} </div>}
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_discount).slice(0,-6)} </div>}
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_net_total).slice(0,-6)} </div>}
            {/* {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_vat).slice(0,-6)} </div>} */}
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_net_total_with_vat).slice(0,-6)} </div>}
        </div>
    ));

    return (
        <>
            <div className='text-xs '>
                <div className='flex justify-between text-xs  pb-3  '>
                    {customer && customerName && 
                        <div className='border flex-1 flex flex-col text-center gap-1 p-2 '> 
                            <div className='flex justify-between '>
                                <span>اسم العميل</span>
                                <span>CUSTOMER NAME</span>
                            </div>
                            <span className='font-bold'>{customer?.fullname[0].text || customer?.fullname[1].text }</span>
                        </div>
                    }
                    {customer?.customerNo && 
                        <div className='border flex-1 flex flex-col text-center gap-1 p-2 '>
                            <div className='flex justify-between '>
                                <span>رقم العميل</span>    
                                <span >CUSTOMER NO</span>
                            </div>
                            <span className='font-bold'>{customer.customerNo}</span>
                        </div>
                    }
                    {customer?.customerVatNo && 
                        <div className='border flex-1 flex flex-col text-center gap-1 p-2'>
                            <div className='flex justify-between '>
                                <span className='text-xs'>الرقم الضريبي للعميل</span>
                                <span>CUSTOMER VAT NO</span>
                            </div>
                            <span className='font-bold'>{customer.customerVatNo}</span>
                        </div>}
                    {customer?.customerAddresses.length > 0 && 
                        <div className='border flex-1 flex flex-col text-center gap-1 p-2'>
                            <div className='flex justify-between '>
                                <span className='text-xs'> عنوان العميل</span>
                                <span>CUSTOMER Address </span>
                            </div>
                            <span className='font-bold '>{customer.customerAddresses[0].desc}</span>
                        </div>}
                    {/* {salesmanName && <div>{t('invoice.Salesman')}: {salesmanName}</div>} */}
                </div>
                <div  className='flex justify-between items-center p-3 bg-slate-10 font-bold border-t border-r border-l rounded-t'>
                    <div className='flex-1 text-center  flex flex-col'>
                        <span>اسم المنتج - الخدمة</span>
                        <span>PRODUCT / SERVICE NAME</span>
                    </div>
                    {
                    !isDelivery && 
                        <div className='w-20 text-center flex flex-col '>
                            <span>سعر الوحدة</span>
                            <span>PRICE UNIT</span>
                        </div>
                    }
                    <div className='w-16 text-center flex flex-col'>
                        <span>{t('invoice.quantity')}</span>
                        <span>QUANTITY</span>
                    </div>
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>السعر</span>
                        <span>PRICE</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>الخصم</span>
                        <span>PRICE</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>إج. بعد الخصم </span>
                        <span>T.A DISCOUNT</span>
                        </div>}
                    {/* {!isDelivery && <div className='w-24 text-center  flex flex-col'>
                        <span>الضريبة ({SETTING_INV_VAT_PERCENT}%)</span>
                        <span>VAT ({SETTING_INV_VAT_PERCENT}%)</span>
                        </div>} */}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>إج. بعد الضريبة</span>
                        <span>T.A VAT</span>
                        </div>}
                </div>
                <div className='border '>{invProductsList}</div>
            </div>
        </>
    )
}

export default InvoiceBody