import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import {BiSolidMessageSquareAdd} from 'react-icons/bi'
import withGuard from "../../utils/withGuard";
import InvListBasic from "../../components/quote/InvListBasic";
import { useCurrent } from "../../hooks";
import { MwButton } from "../../components/ui";
import SearchQuote from "../../components/quote/SearchQuote";
import {RiQuoteText} from 'react-icons/ri'
const ManageQuotes = () => {
    const params = useParams();
    const [invoices,setInvoices] = useState();
    const [loading,setLoading] = useState(true);
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();

    const invoicesHandler = async () => {
        const response = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
        setInvoices(response?.data.data);
        setLoading(false);
        
    }

    const onChangeSearch = (newValue) => {
        console.log(newValue)
        setInvoices(newValue)
    }

    useEffect(()=>{
        invoicesHandler();
    },[])
    
    return (
        <>
            <div className='flex justify-between items-center pb-3'>
            
                <div className=' flex gap-1 text-sm text-gray-400 '>
                    <RiQuoteText size={18} />
                    ادارة عروض الاسعار </div>
                <Link to={`/${params.tenant}/quotes/create`}>
                    <MwButton inGroup={true} type='saveBtn'  >
                        <BiSolidMessageSquareAdd/>
                        انشاء عرض سعر جديد
                        </MwButton>
                </Link>
            </div>
            <hr />
            <div className="bg-slate-50 text-slate-500 pt-3 rounded-lg text-xs h-full">
                <div className="flex items-center justify-center">
                    <div className="flex-1 px-3 font-bold">قائمة عروض الاسعار</div>
                    <SearchQuote className='bg-white' onChangeSearch={onChangeSearch} isOpen={false} />
                </div>
                <div className="my-3">
                    <InvListBasic loading={loading} withBorder={false} data={invoices}/>  
                </div>
            </div>
        </>
    )
}

export default withGuard(ManageQuotes)