import React from 'react'
import {  useTranslation } from 'react-i18next';

const InvoiceBody = ({
    customerName,
    salesmanName,
    invProducts,
    isDelivery,
    SETTING_INV_CURRENCY,
    formatter
    
}) => {
    const [t] = useTranslation('global')
    const invProductsList = invProducts.map((prod) => (
        <div key={prod.id} className={`flex justify-between border-b  ${ prod.is_returned && 'bg-orange-50'}`}>
            <div className='flex-1 p-2 border-e'>{prod.product_name} {prod.is_returned && <span className='font-bold text-orange-300'>[مرتجع]</span> } </div>
            {!isDelivery && <div className='w-20 text-center  border-e p-2'>{formatter.format(prod.price).slice(0,-6)} </div>}
            <div className='w-16 text-center border-e p-2'>{prod.qty}</div>
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.total_price).slice(0,-6)} </div>}
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_discount).slice(0,-6)} </div>}
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_net_total).slice(0,-6)} </div>}
            {/* {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_vat).slice(0,-6)} </div>} */}
            {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_net_total_with_vat).slice(0,-6)} </div>}
        </div>
    ));


    return (
        <>
            <div className='text-xs py-3'>
                <div className='flex justify-between text-xs px-1 pb-3 pt-1'>
                    {customerName && <div>{t('invoice.Customer')}: {customerName}</div>}
                    {salesmanName && <div>{t('invoice.Salesman')}: {salesmanName}</div>}
                </div>
                <div  className='flex justify-between items-center p-3 bg-slate-10 font-bold border-t border-r border-l rounded-t'>
                    <div className='flex-1 text-center  flex flex-col'>
                        <span>اسم المنتج - الخدمة</span>
                        <span>PRODUCT / SERVICE NAME</span>
                    </div>
                    {
                    !isDelivery && 
                        <div className='w-20 text-center flex flex-col '>
                            <span>سعر الوحدة</span>
                            <span>PRICE UNIT</span>
                        </div>
                    }
                    <div className='w-16 text-center flex flex-col'>
                        <span>{t('invoice.quantity')}</span>
                        <span>QUANTITY</span>
                    </div>
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>السعر</span>
                        <span>PRICE</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>الخصم</span>
                        <span>PRICE</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>إج. بعد الخصم </span>
                        <span>T.A DISCOUNT</span>
                        </div>}
                    {/* {!isDelivery && <div className='w-24 text-center  flex flex-col'>
                        <span>الضريبة ({SETTING_INV_VAT_PERCENT}%)</span>
                        <span>VAT ({SETTING_INV_VAT_PERCENT}%)</span>
                        </div>} */}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>إج. بعد الضريبة</span>
                        <span>T.A VAT</span>
                        </div>}
                </div>
                <div className='border '>{invProductsList}</div>
            </div>
            
        </>
    )
}

export default InvoiceBody