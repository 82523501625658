import React from 'react'

const CardAmount = ({label,amountValue,footerValue}) => {
    return (
        <div id='total' className=' flex-1 text-center h-full'>
            <div className=' text-slate-400 text-xs pb-2'>{label}</div>
            { amountValue && 
                <div className='text-xs flex-1 flex flex-col justify-center items-center ' >
                    <div className='font-bold text-base text-slate-600  '>{amountValue}</div>
                    <div className='text-slate-400 my-1'>{!!footerValue && footerValue == 'sar' && 'ريال سعودي'}</div>
                </div>
            }
        </div>
    )
}

export default CardAmount