import React, { useEffect,useState } from 'react'
import { ConfirmDelete, MwButton, MwSpinnerButton } from '../../ui'
import {MdOutlineAddBox} from 'react-icons/md'
import { useCurrent, useDelete, useFetch } from '../../../hooks'
import ListGroupsItem from './ListGroupsItem'
import NoDataFound from '../../ui/NoDataFound'

const ListGroups = ({setMode,idItemSelected,setIdItemSelected}) => {
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const {data:groups,loading:loadingGroups,refreshHandler} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/groups/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:resulltDelete,deleteItem} = useDelete();
    const editHandeler = (id) => {
        setIdItemSelected(id);
        setMode('Edit');
    }

    const deleteHandeler = (id) => {
        setIdItemSelected(id);
        setShowConfirmDelete(true)
    }

    const confirmDeleteHandller = () => {
        deleteItem(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/groups/delete/${idItemSelected}`);
    }

    useEffect(() => {
        refreshHandler();
        setShowConfirmDelete(false);
    },[resulltDelete]);

    useEffect(()=>{
        if(groups){
            console.log(groups);
        }
    },[groups]);

    
    return (
        <>
            <ConfirmDelete  
                onDeleteHandler={confirmDeleteHandller} 
                msg={`هل انت متاكد  من  حذف هذه المجموعه`} 
                onShow={showConfirmDelete} 
                setOnShow={setShowConfirmDelete}
                />
            <div className='flex items-center'>
                <div  className='flex-1 font-bold text-slate-400'>قائمة مجموعات العملاء</div>
                <MwButton inGroup={true} type='saveBtn' onClick={() => setMode('Add')}  >
                    <MdOutlineAddBox size={18}/>
                    <span>أضف مجموعة جديدة</span>
                </MwButton>
            </div>
            <div className='py-3 '>
                {   
                    
                    ( !loadingGroups && groups?.data?.length  > 0 ) ? groups?.data?.map((item) => <ListGroupsItem 
                                                                                key={item._id} 
                                                                                item={item} 
                                                                                editHandeler={editHandeler} 
                                                                                deleteHandeler={deleteHandeler} 
                                                                            />) : 
                                                                            (  groups && groups?.data?.length < 1 ?
                                                                                <NoDataFound msg={`لا توجد مجموعات للعملاء`}/>
                                                                                :
                                                                                    <MwSpinnerButton 
                                                                                        withLabel={false} 
                                                                                        isFullCenter={true} 
                                                                                    />
                                                                            )
                }
            </div>

        </>
    )
}

export default ListGroups