import { useState,useEffect } from 'react'
import {MwButton} from '../../components/ui'
import {updateCustomer} from '../../services/customerService'
import { useCurrent, useFetch, usePut } from '../../hooks'
import CardHeadeWithActions from '../ui/CardHeadeWithActions'
import BasicInfoForm from './forms/BasicInfoForm'
import ResponsiblesForm from './forms/ResponsiblesForm'
import AddressForm from './forms/AddressForm'
import AdvancedForm from './forms/AdvancedForm'
import {langObjType,objLangTextHandler,LangArrayToObjKeyValue} from '../../utils/global'

const EditCustomer = ({id,onHide ,onCustomerCreated = ()=>{} ,setMode}) => {
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangList,currentLangId} = useCurrent()
    const {data:dataCustomer} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/show/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${id}`)
    const [errors,setErrors] = useState({});
    const [tabActive,setTabActive] = useState('TabOne');
    const {data:resultPut,putData} = usePut();
    // Basic Form  
    const [fullname,setFullname] = useState(langObjType())
    const [customerNo,setCustomerNo] = useState('')
    const [groupSelected,setGroupSelected] = useState([]); // customerGroupId
    const [mobileNo,setMobileNo] = useState('')
    const [phoneNo,setPhoneNo] = useState();
    const [email,setEmail] = useState('')
    const [customerNotes,setCustomerNote] = useState('')
    const [isActive,setIsActive] = useState(true)
    // Rresponsibles Form
    const [responsibles,setResponsibles] = useState([]);
    // Addresses Form
    const [address,setAddress] = useState([]);
    // Advanced Form
    const [customerAccountNo,setCustomerAccountNo] = useState()
    const [customerVatNo,setCustomerVatNo] = useState()
    const [limitCredit,setLimitCredit] = useState();


    const formValidate = () => {
        let errorObj = {};

        if(!fullname[currentLangId]){
            errorObj = { ...errorObj, [`fullname.${currentLangId}`] : 'يجب ادخال اسم العميل'};
            
        }

        if(isNaN(mobileNo)){
            errorObj.mobileNo = 'رقم الجوال يجب ان يكون ارقام';
        }
        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            console.log('errors',errors)
            return true
        }
        return false;
    }
    const saveHandler = async() => { 
        // e.preventDefault();
        if(formValidate()) {
            return  ;
        }
        const _data={
            fullname: objLangTextHandler(fullname),
            customerNo,
            mobileNo,
            phoneNo,
            email,
            customerNotes,
            limitCredit,
            customerAccountNo,
            customerVatNo,
            customerGroupsIds: groupSelected.map(el => el.value),
            responsibles:responsibles,
            customerAddresses:address,
            isActive,
            tenantId:currentTenantId,
            companyId:currentCompanyId,
            branchId:currentBranchId
        }

        // const updated = await updateCustomer(id,data)
        putData(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/update/${id}`,_data);
    };

    const cancelHandle = () => {
        onHide();
    }

    useEffect(() => {
        if(resultPut){
            onHide ? cancelHandle() : setMode('List');
            onCustomerCreated();
        }
    },[resultPut]);
    
    useEffect(() => {
        if(dataCustomer){
            console.log('dataCustomer',dataCustomer);
            // Set Basic Form
            setCustomerNo(dataCustomer?.data?.customerNo);
            setFullname(LangArrayToObjKeyValue(dataCustomer?.data?.fullname));
            const groups = dataCustomer.data?.customerGroupsIds?.map(el => {
                const grouplist = {
                    label: LangArrayToObjKeyValue(el.groupName)?.[currentLangId] || el.groupName[0].text ,
                    value: el._id,
                    lang: currentLangId
                };
                return grouplist;
            });
            setGroupSelected(groups)
            setMobileNo(dataCustomer.data?.mobileNo)
            setPhoneNo(dataCustomer.data?.phoneNo)
            setEmail(dataCustomer.data?.email)
            setCustomerNote(dataCustomer.data?.customerNotes)
            setIsActive(dataCustomer.data?.isActive)
            // Set Responsiibles Form
            setResponsibles(dataCustomer.data?.responsibles)
            // Set Addresses
            setAddress(dataCustomer.data?.customerAddresses)
            setCustomerAccountNo(dataCustomer.data?.customerAccountNo)
            setCustomerVatNo(dataCustomer.data?.customerVatNo)
            setLimitCredit(dataCustomer.data?.limitCredit)

        }
    },[dataCustomer]);
    return (
        <div className={`${onHide && 'p-5'}`} >
            <CardHeadeWithActions title={`أضف عميل جديد`}>
                { onHide && <MwButton inGroup={true}  type='cancelBtn' onClick={cancelHandle}>الغاء</MwButton> }
                <MwButton inGroup={true} type='cancelBtn' onClick={() => setMode('List')}  >
                    ادارة العملاء
                </MwButton>
                    <MwButton actionType={`button`} inGroup={true} onClick={saveHandler}   type='saveBtn' >
                    حفظ العميل
                    </MwButton>
            </CardHeadeWithActions>
            <form  >
                <div className=' bg-slate-50 rounded-lg text-xs p-3 flex flex-col gap-1'>

                    <ul className='flex gap-3 items-centers'>
                        <li onClick ={()=>setTabActive('TabOne')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabOne' ? 'bg-indigo-100 font-bold' : 'bg-slate-100'}`}>المعلومات الاساسية</li>
                        <li onClick ={()=>setTabActive('TabTwo')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabTwo' ? 'bg-indigo-100 font-bold': 'bg-slate-100'}`} >بيانات المسئول</li>
                        <li onClick ={()=>setTabActive('TabThree')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabThree' ? 'bg-indigo-100 font-bold': 'bg-slate-100'}`} >العناوين</li>
                        <li onClick ={()=>setTabActive('TabFour')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabFour' ? 'bg-indigo-100 font-bold': 'bg-slate-100'}`} >بيانات متقدمة</li>
                    </ul>
                    {
                        tabActive === 'TabOne' && 
                        <BasicInfoForm
                            currentTenantId = {currentTenantId} 
                            currentCompanyId = {currentCompanyId}
                            currentBranchId = {currentBranchId}
                            currentLangId = {currentLangId}
                            currentLangList = {currentLangList}
                            errors = {errors}
                            customerNo = {customerNo}
                            setCustomerNo = {setCustomerNo}
                            groupSelected = {groupSelected}
                            setGroupSelected = {setGroupSelected}
                            fullname = {fullname}
                            setFullname = {setFullname}
                            mobileNo = {mobileNo}
                            setMobileNo = {setMobileNo}
                            phoneNo = {phoneNo}
                            setPhoneNo = {setPhoneNo}
                            email  = {email}
                            setEmail = {setEmail}
                            customerNotes = {customerNotes}
                            setCustomerNote = {setCustomerNote}
                            isActive = {isActive}
                            setIsActive = {setIsActive}
                            /> 
                    }
                    {
                        tabActive === 'TabTwo' &&
                        <ResponsiblesForm
                            currentLangId = {currentLangId}
                            currentLangList = {currentLangList}
                            errors = {errors}
                            responsibles = {responsibles}
                            setResponsibles = {setResponsibles}
                            />
                    }
                    {
                        tabActive === 'TabThree' &&
                        <AddressForm 
                            errors ={errors}
                            address = {address}
                            setAddress = {setAddress}
                            />
                    }
                    {
                        tabActive === 'TabFour' &&
                        <AdvancedForm
                            errors = {errors}
                            customerAccountNo = {customerAccountNo}
                            setCustomerAccountNo = {setCustomerAccountNo}
                            customerVatNo = {customerVatNo}
                            setCustomerVatNo = {setCustomerVatNo}
                            limitCredit = {limitCredit}
                            setLimitCredit = {setLimitCredit}
                            />
                    }
                </div>
            </form>
        </div>
    )
}

export default EditCustomer