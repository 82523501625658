import React, { useEffect, useState, useRef } from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { AiOutlinePrinter,AiOutlineEdit } from 'react-icons/ai';
import { MdOutlinePaid } from 'react-icons/md';

import {getCompanyById} from '../../utils/tenantManager.js'
import {arrayNameByLang,printableDiv} from '../../utils/global.js'
import { useFetch,useLogs,useInvoice } from '../../hooks';
import { ConfirmDelete,MwButton, MwSpinner } from '../ui';
import InvoiceHeader from './show/InvoiceHeader.jsx';
import InvoiceBody from './show/InvoiceBody.jsx';
import InvoiceFooter from './show/InvoiceFooter.jsx';
import {  useTranslation } from 'react-i18next';


const ShowInvoice = ({isPrint=false,isDelivery=false}) => {
    const divPrintRef = useRef();

    const [t] = useTranslation('global')
    const params = useParams();
    const navigate = useNavigate();
    const {formatter} = useInvoice();

    const [showConfirmModal,setShowConfirmModal] = useState(false);

    const lang = useSelector(state => state.lang);
    const invoiceSettings = useSelector(state => state.settings).quoteSettings;
    const company = getCompanyById(params.companyId);
    const companyName =  arrayNameByLang(company?.name,lang.value)?.text;
    const companyDesc =  arrayNameByLang(company?.desc,lang.value)?.text;
    const companyAddress =  arrayNameByLang(company?.mainAddress,lang.value)?.text;
    const vatNo = company.vatNumber;
    const commercialRegisteration = company?.commercialRegisteration;
    const invTerms = invoiceSettings.INV_TERMS_PRINT
    const SETTING_SHOW_TERMS_ON_PRINT = invoiceSettings.SHOW_TERMS_ON_PRINT
    const INV_CURRENCY = invoiceSettings?.INV_CURRENCY
    const SETTING_INV_CURRENCY = INV_CURRENCY  || 'SAR'
    const {resultLogsPost,loadingLogsPost,errorLogsPost,postDataHandler} = useLogs()

    const {data:invData,loading:invLoading,error} = useFetch(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/show/${params.id}`)
    

    useEffect(() => {
        if(invData){
        }
    },[invData])
    
    const setLogsHandler =  (itemEn='delete',itemAr='حذف') => {
        const _data = {
            moduleName: "SALES",
            resourceName: "QUOTES",
            eventName:"DELETE",
            logContentEn: `${itemEn} quote No: ${invData.inv_no}`,
            logContentAr: `${itemAr} عرض سعر برقم : ${invData.inv_no}`,
        }
        postDataHandler(_data);
    }

    const deleteConfirm = async() => {
        const urlDeleteReq = `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/delete/${params.id}`;
        const res = await axios.delete(urlDeleteReq);
        if(res.data){
            setLogsHandler();
            navigate(`/${params.tenant}/quotes`);
        }
        console.log(`Error deleting`);
    }

    const deleteHandler = () => {
        setShowConfirmModal(true);
    }

    const handlePrint = () => {
        setLogsHandler('print','طباعة');
        printableDiv(divPrintRef,lang?.value)
    };
  
    return (
        <>
            { !invLoading  ? 
            <div>
                <ConfirmDelete
                    title={`هل انت متاكد من حذف عرض السعر`}
                    msg={`هل انت متاكد من حذف عرض السعر`}
                    onShow={showConfirmModal}
                    setOnShow={setShowConfirmModal}
                    onDeleteHandler={deleteConfirm}
                />
                <div className='flex py-2 '>
                    <MwButton 
                        size = 'sm'
                        inGroup = {true}
                        type = 'secondary' 
                        onClick={handlePrint}>
                            <AiOutlinePrinter size={16}/> طباعة
                    </MwButton>
                    <MwButton 
                            size = 'sm'
                            inGroup = {true}
                            type = 'secondary-inline-end' 
                            onClick={()=>{
                                navigate(`/${params.tenant}/quotes/edit/${params.id}`)
                            }}>
                                <AiOutlineEdit size={16}/> تعديل 
                        </MwButton>
                    <MwButton 
                            size = 'sm'
                            inGroup = {true}
                            type = 'deleteBtn-inline' 
                            onClick={  deleteHandler }>
                                <AiOutlinePrinter size={16}/> حذف 
                    </MwButton>
                    <MwButton 
                            size = 'sm'
                            inGroup = {true}
                            type = 'saveBtn' 
                            onClick={ () =>{} }>
                                <MdOutlinePaid size={16}/> انشاء فاتورة لعرض السعر
                        </MwButton>
                </div>
                <div className='bg-slate-50 p-3 mt-2 rounded-lg'>
                    <div id='printableContent'  ref={divPrintRef} className='p-2 mt-2  text-sm'>
                        <InvoiceHeader
                            logo = {company.logo} 
                            companyName={companyName}
                            companyDesc={companyDesc}
                            vatNo = {vatNo}
                            commercialRegisteration = {commercialRegisteration}
                            inv_no = {invData.inv_no}
                            inv_date = {invData.inv_date}
                            inv_date_due = {invData.inv_date_due}
                            companyAddress = {companyAddress}
                            invoiceSettings = {invoiceSettings}
                        />
                        <InvoiceBody
                            customerName = {invData.customer_name}
                            salesmanName = {invData.salesman_name}
                            invProducts = {invData.quote_details}
                            isDelivery = {isDelivery}
                            formatter = {formatter}
                            SETTING_INV_CURRENCY = {SETTING_INV_CURRENCY}
                            
                        />
                        <InvoiceFooter
                            totalAmount = {invData.total_amount}
                            vatAmount = {invData.vat_amount}
                            discountAmount = {invData.discount_amount}
                            netAmount = {invData.net_amount}
                            invTerms = {invTerms}
                            SETTING_PRICE_INCLUDE_VAT = {invoiceSettings.SETTING_PRICE_INCLUDE_VAT}
                            SETTING_INV_VAT_PERCENT = {invoiceSettings.INV_VAT_PERCENT}
                            SETTING_SHOW_TERMS_ON_PRINT = {SETTING_SHOW_TERMS_ON_PRINT}
                            SETTING_INV_CURRENCY = {SETTING_INV_CURRENCY}
                            isDelivery={isDelivery}
                            currentLang = {lang}
                            formatter = {formatter}
                            invNotes = {invData.notes}
                        />
                    </div>
                </div>
            </div> : 
            <MwSpinner/> 
            }
        </>
    )
}

export default ShowInvoice