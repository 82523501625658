import React, { useState } from 'react'
import ListCustomers from './ListCustomers'
import CreateCustomer from './CreateCustomer'
import EditCustomer from './EditCustomer'

const CustomersHome = () => {

    const [mode,setMode] = useState('List');
    const [idItemSelected,setIdItemSelected] = useState();

    return (
        <>
            {mode === 'List' && <ListCustomers setMode={setMode} idItemSelected={idItemSelected} setIdItemSelected = {setIdItemSelected} /> }
            {mode === 'Add' && <CreateCustomer setMode={setMode} /> }
            {mode === 'Edit' && <EditCustomer id = {idItemSelected} setMode={setMode} /> }
        </>
    );

}

export default CustomersHome