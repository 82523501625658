import React, { useState } from 'react'
import { useCurrent } from '../../../hooks';
import FormLangBar from '../../FormLangBar';
import { MwInputText, MwSelector, MwSwitch, MwTextArea } from '../../ui';
import {PRODUCT_TYPE_LIST} from '../../../pages/products/constants'

const BaiscInfo = ({
        productName,
        setProductName,
        productDesc,
        setProductDesc,
        isShowOnWebsite,
        setIsShowOnWebsite,
        isActive,
        setIsActive,
        errors,
        productTypeSelected,
        setProductTypeSelected
    }) => {
    const {currentLangList,currentLangId} = useCurrent();
    const [formLang,setFormLang] = useState(currentLangId);

    return (
        <div className=''>
            <FormLangBar formLang={formLang} setFormLang={setFormLang} currentLangList = {currentLangList}/>
            <div className='flex flex-col '>
                { currentLangList.map((lang) => (
                    <div key={lang.langCode}>
                        <div className={` ${ formLang === lang?.langCode ? 'block' : 'hidden' } `}>
                            <div className=' '>
                            <MwInputText
                                label={`اسم المنتج (${lang?.langName})`}
                                id={`productName_${lang?.langCode}`} 
                                value={productName[lang?.langCode]}
                                invalid={!!errors[`productName.${lang?.langCode}`] }
                                invalidMsg = {errors[`productName.${lang?.langCode}`]}
                                onChange={(e)=>{
                                    setProductName({...productName, [lang?.langCode]:e.target.value});
                                }}
                            />
                            </div>
                            <div className='my-2'>
                                <MwTextArea
                                    id='productDesc'
                                    value={productDesc[lang?.langCode]}
                                    placeholder={`وصف المنتج (${lang.langName}) `}
                                    rows={10}
                                    onChange={(e) => setProductDesc({...productDesc,[lang?.langCode]:e.target.value})}
                                />
                            </div>
                            <div>
                                <MwSelector
                                    label = {'نوع المنتج'}
                                    initalValue = 'أختار نوع المنتج...'
                                    _data = {PRODUCT_TYPE_LIST}
                                    dataType = 'labelValue'
                                    withAddNew = {false}
                                    withSearch = {false}
                                    selectedItem = {productTypeSelected}
                                    setSelectedItem = {setProductTypeSelected}
                                    invalid = {!!errors?.productType}
                                    invalidMsg = {errors?.productType}
                                    />
                            </div>
                        </div>
                        
                    </div>
                )) }
            </div>
            <div id='OptionsBock' className='flex flex-col justify-between pb-2 px-1'>
                <div className='flex justify-between items-center py-4  border-b mb-2'>
                    <label className='text-xs text-slate-400'>  يظهر / لا يظهر (علي الموقع الالكتروني) </label>
                    <MwSwitch custKey='isActive' isActive={isShowOnWebsite} setIsActive={setIsShowOnWebsite} onChangeSwitch={()=> setIsShowOnWebsite(!isShowOnWebsite)} />
                </div>
                <div className='flex justify-between items-center py-4  border-b mb-2'>
                    <label className='text-xs text-slate-400'>مفعل / غير مفعل</label>
                    <MwSwitch custKey='isActive' isActive={isActive} setIsActive={setIsActive} onChangeSwitch={() => setIsActive(!isActive)} />
                </div>
            </div>
        </div>
    )
}

export default BaiscInfo