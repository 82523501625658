import React from 'react'
import {  useTranslation } from 'react-i18next';
import {MwInputText, MwTextArea} from '../../ui/';
import CardAmount from '../../ui/CardAmount';
import PromoCodeBox from '../PromoCodeBox';
import TagList from '../../ui/TagList';

const InvFormFooter = ({
    invNotes,
    setInvNotes,
    invTotalAmount,
    totalAmountWithDiscount,
    promoCode,
    setPromoCode,
    discount,
    vat,
    netTotal,
    havePromoCode,
    setHavePromoCode,
    promoCodeHandler,
    discountOnchange,
    SETTING_PRICE_INCLUDE_VAT,
    SETTING_INV_CURRENCY,
    tagList,
    setTagList
}) => {
    const [t] = useTranslation('global')
    return (
        <div id='footer-inv' >
                    <MwTextArea 
                        placeholder={t('invoice.enterNotes')}
                        value={invNotes}
                        onChange={(e)=>setInvNotes(e.target.value)}
                        />
                    <div  className='flex md:flex-row flex-col justify-between '>

                        <TagList
                            tagList = {tagList} 
                            setTagList = {setTagList}
                        />
                        <PromoCodeBox
                            havePromoCode = {havePromoCode}
                            setHavePromoCode = {setHavePromoCode}
                            promoCode = {promoCode}
                            setPromoCode = {setPromoCode}
                            promoCodeHandler = {promoCodeHandler}
                            />
                    </div>
                    <hr className='my-2'/>

                    <div className='flex md:flex-row flex-col gap-2  items-top p-3 justify-between text-sm'>
                        <div className="flex-1  flex items-center justify-between">
                            <CardAmount
                                label= 'الإجمالي قبل الخصم'
                                amountValue={invTotalAmount}
                                footerValue={SETTING_INV_CURRENCY}
                                />
                            <div id='discount-card' className=' flex-1 text-center'>
                                <div className=' text-slate-400 text-xs'> الخصم علي إجمالي الفاتورة </div>
                                <MwInputText
                                    value={discount}
                                    // placeholder={t('invoice.discount')}
                                    classNameInput = 'my-1'
                                    size='sm'
                                    onChange={(e)=>discountOnchange(e.target.value)}
                                />
                                {/* <div className='text-slate-400 text-xs'>{ SETTING_INV_CURRENCY }</div> */}
                            </div>
                            <CardAmount
                                label= 'الإجمالي بعد الخصم'
                                amountValue={ totalAmountWithDiscount }
                                footerValue={SETTING_INV_CURRENCY}
                                />
                        </div>
                        <div className="flex-1 flex items-center justify-between">
                            <CardAmount
                                label={t('invoice.vatAmount')}
                                amountValue={vat}
                                footerValue={SETTING_INV_CURRENCY}
                                />
                            <CardAmount
                                label={t('invoice.netTotal')}
                                amountValue={netTotal}
                                footerValue={SETTING_INV_CURRENCY}
                                />                        
                        </div>
                    </div>
                    <hr  />
                    <div className='pt-2 flex flex-col justify-center items-center'>
                        { SETTING_PRICE_INCLUDE_VAT === 'true' ?
                            <div className='flex justify-center items-center text-xs text-slate-400 '>{t('invoice.priceIncludesVat')}</div> : 
                            <div className='text-xs text-slate-400 text-center'>{t('invoice.priceNotIncludesVat')}</div> 
                            
                        }
                    </div>
                </div>
    )
}

export default InvFormFooter