import React, { useEffect, useState } from 'react'
import {useInvoice,usePut,useCurrent} from '../../hooks/'
import { BasicSelector, MwButton, MwInputText, MwSpinnerButton } from '../../components/ui'
import { useDispatch } from 'react-redux'
import {updateSettings} from '../../store/settingSlice'
import SettingListItem from '../../components/settings/SettingListItem'

const QuoteSettings = ({refreshTenant,filterDefineSetting}) => {
    const dispatch = useDispatch();
    const {SETTING_QUOTE} = useInvoice()
    const [invSetting,setInvSetting] = useState(SETTING_QUOTE)
    const {data,loading,error,putData} = usePut()
    const {currentCompanyId,currentTenantId,currentLangId} = useCurrent();

    const invSettingArray = Object.entries(invSetting);
    const invSettingList =  invSettingArray.map(([settingName,settingValue]) =>(
        <SettingListItem
            key={settingName}
            settingName = {settingName}
            settingValue = {settingValue}
            filterDefineSetting = {filterDefineSetting}
            setGeneralSetting = {setInvSetting}
            generalSetting = {invSetting}
            currentLangId = {currentLangId}
            prefixLang = 'invoice'
            />
    ));

    useEffect(()=>{
        if(data){
            refreshTenant();
        }
    },[data]);


    const saveHandler = async() => {
        const invSettingArray = Object.entries(invSetting).map(([key, value]) => ({ name:key , value: value }));
        const _data = {
            companyId:currentCompanyId,
            settingName: "quote",
            settingValue:invSettingArray
        }
        console.log(_data);
        putData(`${process.env.REACT_APP_Auth_API_BASE_URL}/tenants/setting/${currentTenantId}`,_data)
        dispatch(updateSettings({
            typeSetting : 'quote',
            value : invSetting
        }));
    }

    return (
        <>
        <div>
            <div className='flex  justify-between items-center gap-2 text-xs text-slate-500 font-bold px-1 '>
                    <div>
                        إعدادات عروض الاسعار : 
                    </div>
                    { loading ? <MwSpinnerButton/> : <MwButton 
                                    inGroup = {true}
                                    size='sm'
                                    onClick={saveHandler}
                                    type = 'saveBtn'  > حفظ التغيرات
                                    </MwButton> }
            </div>
            <div className='p-3 my-3  rounded-lg border border-slate-200'>{invSettingList}</div>
        </div>
        </>
    )
}

export default QuoteSettings