import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {usePost} from '../hooks/'
import { tenant,getCompanyById } from '../utils/tenantManager.js';
import { arrayToObjKeyValue } from '../utils/global.js';

const useGenQuoteNo = () => {
    const user = useSelector((state) => state.auth.user);

    const {data,postData} = usePost();
    const [newInvNo,setNewInvNo] = useState()
    const [loading,setLoading] = useState(true);

    const tenantId = tenant()?._id;
    const companySelected  = useSelector((state)=>state.currentCompany)
    const branchSelected = useSelector((state)=>state.currentBranch)
    const getSettingsByCompanySelected = getCompanyById(companySelected.value)

    const SETTING_QUOTE = arrayToObjKeyValue(getSettingsByCompanySelected?.settings?.quote);
    const userPrefix = btoa(user?._id).substring(1,4)+SETTING_QUOTE.INV_NO_SEPERATE;

    const genInvoiceNo = () => {
        if(!SETTING_QUOTE) return 
        const invPrefix = SETTING_QUOTE?.INV_PREFIX;
        let _payload = {
            tenant_id:tenantId,
            company_id:companySelected.value,
            branch_id:branchSelected.value,
        }
        console.log('SETTING_QUOTE.INV_NO_FORMAT',SETTING_QUOTE.INV_NO_FORMAT)
        if(SETTING_QUOTE.INV_NO_FORMAT === 'Number'){
            postData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/get-last-inv-no-by-tenant`,_payload);
        }else{
            _payload.prefix = SETTING_QUOTE.INV_NO_FORMAT === 'UserAndNumber' ? userPrefix : invPrefix
            postData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/get-last-inv-no-by-tenant-with-prefix`, _payload);
        }
    }

    useEffect(()=>{
        genInvoiceNo();
    },[])
    
    useEffect(()=>{
        console.log('data',data,SETTING_QUOTE.INV_NO_FORMAT)
        let _newInvNo = 1;
        if(data || data === 0){
            if(SETTING_QUOTE.INV_NO_FORMAT === 'Number'  ){
                let no = data
                if(!data){
                    _newInvNo = parseInt(SETTING_QUOTE.INV_NO_START) +1; 
                    console.log('!data invNO=> ',_newInvNo)
                }else if(isNaN(no)){
                    const _no = no.split(SETTING_QUOTE.INV_NO_SEPERATE);
                    _newInvNo = parseInt(_no[1]) + 1
                    console.log('isNaN(no) invNO => ',_newInvNo)
                }else{
                    _newInvNo = parseInt(no) + 1
                    console.log('else invNO => ',_newInvNo)
                }
                console.log('newInvNo invNO=> ',_newInvNo);
                setNewInvNo(_newInvNo)
                setLoading(false)
            }else{
                const invFormat =  data?.toString().split(SETTING_QUOTE.INV_NO_SEPERATE)               
                if (SETTING_QUOTE.INV_NO_FORMAT === 'TextAndNumber'){
                    invFormat[invFormat.length-1] = parseInt(invFormat[invFormat.length-1])+1;
                    setNewInvNo(`${invFormat.join(SETTING_QUOTE.INV_NO_SEPERATE)}`)
                }else if (SETTING_QUOTE.INV_NO_FORMAT === 'UserAndNumber'){
                    if(!data){
                        setNewInvNo(`${userPrefix}${SETTING_QUOTE.INV_NO_START}`) ;
                    }else{

                        let invNo = parseInt(invFormat[invFormat?.length-1])+1;
                        if(invNo === 1){
                            invNo = SETTING_QUOTE.INV_NO_START;
                        }
                        setNewInvNo(`${userPrefix}${invNo}`)
                    }
                }else{
                    setNewInvNo(SETTING_QUOTE.INV_NO_START );
                }
                setLoading(false)
            } 
        }
        
        
    },[data])

    return {newInvNo,loading}
}

export default useGenQuoteNo;