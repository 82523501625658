import React,{useEffect, useState} from 'react'
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import TransactionsList from './TransactionsList';
import TransactionsFilter from './TransactionsFilter';
import { useCurrent, usePost } from '../../hooks';


const Transactions = () => {
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId} = useCurrent();
    const {data:transactionsResult,loading:transactionsLoading,postData:getTransactions} = usePost();
    const _urlTransactions = `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/payment-transactions/filter`;
    const [params,setParams] = useState({
        tenant_id: currentTenantId,
        company_id: currentCompanyId,
        branch_id: currentBranchId
    });

    // Filter State
    const [filterState,setFilterState] = useState({
        startDate: '',
        endDate: '',
    });
    const [paymentMethodSelected,setPaymentMethodSelected] = useState();
    const [typeSelected,setTypeSelected] = useState();
    const [userSelected,setUserSelected] = useState();
    const filterHandler = () => {
        setParams((state) =>({
            ...state,
            created_by: userSelected?.value ,
            start_date: filterState.startDate ,
            end_date: filterState.endDate ,
            type: typeSelected?.value ,
            payment_method_id: paymentMethodSelected?.value ,
        }));
        getTransactions(_urlTransactions,{
            ...params,
            created_by: userSelected?.value ,
            start_date: filterState.startDate ,
            end_date: filterState.endDate ,
            type: typeSelected?.value ,
            payment_method_id: paymentMethodSelected?.value ,
        });
    }

    //----------------------------------------------------------------

    

    const _init = () =>{
        
        getTransactions(_urlTransactions,params);
        
    }

    const paginateHandler = (_url) => {
        getTransactions(_url,params);
    }

    
    useEffect(() =>{
        _init();
    },[]);
    
    useEffect(() =>{
        if(transactionsResult){
            console.log('transactionsResult',transactionsResult)
        }
    },[transactionsResult])

    return (
        <div className='flex flex-col  '>
            <div className='flex-1 flex gap-1 items-center py-3 text-slate-500 text-sm'>
                <RiMoneyDollarCircleLine size={18}/>
                <span>المدفوعات والمقبوضات</span>
            </div>
            <div className='flex gap-3 justify-between'>
                <TransactionsList 
                    lang={currentLangId} 
                    data={transactionsResult} 
                    loading={transactionsLoading}
                    paginateHandler = {paginateHandler}
                    />
                <TransactionsFilter
                        filterState = {filterState}
                        setFilterState = {setFilterState}
                        typeSelected = {typeSelected}
                        setTypeSelected = {setTypeSelected}
                        userSelected    = {userSelected}
                        setUserSelected = {setUserSelected}
                        currentTenantId = {currentTenantId}
                        currentCompanyId = {currentCompanyId}
                        currentBranchId = {currentBranchId}
                        currentLangId = {currentLangId}
                        filterHandler = {filterHandler}
                        paymentMethodSelected = {paymentMethodSelected}
                        setPaymentMethodSelected = {setPaymentMethodSelected}
                    />
            </div>
        </div>
    )
}

export default Transactions