import axios from 'axios';
import { useState} from 'react'

const useDelete = () => {
    const [loading,setLoading] = useState(false);
    const [error,setError]  = useState(null);
    const [data,setData] = useState();
    const token = localStorage.getItem('token');

    const deleteItem = async (url) => {
        setLoading(true);
        setError(null);
        try {
            const res = await axios.delete(url,{
              headers:{
                  'Authorization': `Bearer ${token}` 
              }
          });
            setData(res.data)
          // Handle successful deletion
        } catch (error) {
            setError(error);
          // Handle error
        } finally {
            setLoading(false);
        }
        };

    return {deleteItem,data,loading,error}
}

export default useDelete