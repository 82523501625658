import React, { useState } from 'react'
import ListGroups from './ListGroups'
import CreateGroup from './CreateGroup';
import EditGroup from './EditGroup';

const CustomersGroups = () => {

    const [mode,setMode] = useState('List');
    const [idItemSelected,setIdItemSelected] = useState();

    return (
        <>
            {mode === 'List' && <ListGroups setMode={setMode} idItemSelected={idItemSelected} setIdItemSelected = {setIdItemSelected} /> }
            {mode === 'Add' && <CreateGroup setMode={setMode} /> }
            {mode === 'Edit' && <EditGroup setMode={setMode} id={idItemSelected} /> }
        </>
    );

}

export default CustomersGroups