import {useEffect, useState,useRef} from 'react'
import * as XLSX from 'xlsx';
import { MwButton, MwInputText,MwSelector, MwSpinnerButton, NoDataFound } from '../ui'
import {  useTranslation } from 'react-i18next';
import { useCurrent, useFetch, usePost } from '../../hooks';
import SalesReportsList from './SalesReportsList';
import MwCardWrapper from '../ui/MwCardWrapper';
import {printableDiv} from '../../utils/global';
import { FaRegFileExcel,FaPrint } from "react-icons/fa";


const SalesReports = () => {
    const divPrintRef = useRef();
    const [t] = useTranslation('global')
    const {currentLangId} = useCurrent();
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const [formStates,setFormStates] = useState({
        startDate:'',
        endDate:'',
    });
    const [customerSelected,setCustomerSelected] = useState();
    const [salesmanSelected,setSalesmanSelected] = useState();
    
    const {data:salesmans,loading:loadingSalesmans} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/salesmans/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)
    const {data:customers,loading:loadingCustomers} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)
    const {data:filterDataResult,loading:filterLoading,postData} = usePost();


    const onClickNewCustomer = () => {};
    const onClickNewSalesman = () => {};

    const submitHandler = (e) => {
        e.preventDefault();
        const _data = {
            tenant_id: currentTenantId,
            company_id: currentCompanyId,
            branch_id: currentBranchId,
            inv_start_date: formStates.startDate,
            inv_end_date: formStates.endDate,
            salesman_id:salesmanSelected ,
            customer_id:customerSelected,
            page_limit:'all'
        }
        console.log('submitHandler',_data);
        postData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/filterBy/`,_data);
    };

    useEffect(() => {
        if(filterDataResult){
            console.log('filterDataResult',filterDataResult)
        }
    },[filterDataResult]);


    const printHandler = () => {
        printableDiv(divPrintRef,currentLangId)
    };

    const exportExcel = () => {
        if(filterDataResult.data){
            const wb =  XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(filterDataResult.data);
            XLSX.utils.book_append_sheet(wb, ws,"تقرير المبيعات");
            XLSX.writeFile(wb,"تقرير المببيعات.xlsx")
        }
    } 

    return (
        <div >
            <div id='header' className=''>
                <h3 className='pb-3'>تقرير المبيعات</h3>
            </div>
            <MwCardWrapper>
                <form onSubmit={submitHandler} className='flex flex-col gap-5'> 
                    <div id='formPeriod' className='flex gap-5'>
                        <div className='flex-1' >
                            <MwInputText
                                inputType='date'
                                label='بداية الفترة'
                                value={formStates.startDate}
                                onChange={(e) => setFormStates({...formStates,startDate:e.target.value})}
                                />
                        </div>
                        <div className='flex-1'>
                            <MwInputText
                                inputType='date'
                                label='نهاية الفترة'
                                value={formStates.endDate}
                                onChange={(e) => setFormStates({...formStates,endDate:e.target.value})}
                                />
                        </div>
                    </div>
                    <div id='formCustomerAndSalesman' className='flex gap-5'>
                        <div className="relative flex-1 flex  flex-col ">
                            { customers && !loadingCustomers && <MwSelector 
                                label={t('invoice.selectCustomer')}
                                _data={customers.data} 
                                dataType='customer' 
                                onClickNew = {onClickNewCustomer}
                                selectedItem = {customerSelected}
                                setSelectedItem = {setCustomerSelected}
                                />
                            }
                        </div>
                        <div className="relative flex-1 flex flex-col ">
                            {salesmans && !loadingSalesmans && <MwSelector
                            label={t('invoice.selectSalesman')}
                            _data={salesmans.data} 
                            dataType='salesman' 
                            onClickNew={onClickNewSalesman}
                            selectedItem = {salesmanSelected}
                            setSelectedItem = {setSalesmanSelected}
                            />
                            }
                        </div>
                    </div>
                    <div className='pt-3 flex justify-end'>
                    { !filterLoading ? <MwButton type='saveBtn' >اعداد التقرير</MwButton> : <MwSpinnerButton/> }
                    </div>
                </form>
            </MwCardWrapper>
            {
            ( filterLoading || filterDataResult ) &&
                <MwCardWrapper id='Result' >
                    {/* { filterLoading && <MwSpinnerButton isFullCenter={true} />} */}
                    {   filterDataResult && (
                        filterDataResult?.data?.length > 0 ? 
                            <div>
                                <div className='flex justify-between'>
                                    <div className='px-2 text-slate-500'>
                                    </div>
                                    <div className='px-3'>
                                        <ul className='flex gap-1'>
                                            <li onClick={exportExcel}  className='px-2 py-1 flex items-center gap-1 bg-slate-100 hover:font-bold cursor-default '>
                                                <FaRegFileExcel /> 
                                                <span className='text-sm'> تصدير اكسل </span>
                                            </li>
                                            <li onClick={printHandler} className='px-2 py-1 flex items-center gap-1 bg-slate-100 hover:font-bold cursor-default '> 
                                                <FaPrint />
                                                <span className='text-sm'>طباعة</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div id='printableDiv'  ref={divPrintRef} >
                                    <div className=' text-slate-700'>
                                        <div className='font-bold' >تقرير المبيعات لفترة </div>
                                        <div>
                                            <span> من {formStates.startDate}</span>
                                            <span> الي {formStates.endDate}</span>
                                        </div>
                                    </div>
                                    <SalesReportsList data={filterDataResult} />
                                </div>
                            </div>:
                            <NoDataFound msg={`لا توجد نتائج لبحثك`}/>)
                    }
                </MwCardWrapper>
            }
        </div>
    )
}

export default SalesReports