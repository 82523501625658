import {  useTranslation } from 'react-i18next';

const InvoiceHeader = ({
    logo,
    companyName,
    companyDesc,
    vatNo,
    commercialRegisteration,
    inv_no,
    inv_date,
    inv_date_due,
    companyAddress,
    invoiceSettings
}) => {

    const [t] = useTranslation('global')
    console.log('invoiceSettings',invoiceSettings);
    return (
        <div >
            <div className='flex justify-between'>
                <div className='flex gap-1'>
                    {invoiceSettings?.SHOW_LOGO_ON_PRINT === 'true' && <div><img src={logo} className='w-24' /></div> }
                    
                </div>
                <div className='flex items-center justify-center font-bold text-xl'> عرض سعر  <br/> Offer Price </div>
                <div className='ps-2 '>
                        <div className='font-bold py-2 text-sm' >{companyName}</div>
                        <div className='flex flex-col gap-1 text-xs text-gray-500'>
                            <div>{companyDesc}</div>
                            <div className='flex items-center justify-between gap-3'>
                                <span>   سجل تجاري:</span>
                                <span className='font-bold'>{commercialRegisteration}</span>
                                <span>:.C.R </span>
                            </div>
                            <div className='flex items-center justify-between gap-3'>
                                <span>  الرقم الضريبي:</span>
                                <span className='font-bold'>{vatNo}</span>
                                <span>:VAT NO</span>
                            </div>
                            <div >{companyAddress}</div>
                        </div>
                    </div>
            </div>  
            {/* <hr className='mx-2 my-2'/>           */}
            <div  className='flex justify-between text-xs rounded-t-lg border  pt-2 mt-3 '>
                <div className='border-l border-r flex-1 flex items-center justify-between gap-3 p-2  '> 
                <div className='flex flex-col text-center'>
                    <span> رقم العرض</span>
                    <span>QUOTATION NO</span>
                </div>
                    <span className='font-bold'>{inv_no}</span>
                </div>
                <div className=' flex-1 flex items-center justify-between gap-3 p-2 '> 
                    <div className='flex flex-col text-center'>
                        <span>تاريخ الاصدار</span>
                        <span>ISSUE DATE</span>
                    </div>
                    <span className='font-bold'>{inv_date.substring(0,10)}</span>
                </div>
                
                {/* <div className=' flex-1 flex items-center justify-between gap-3 p-2 '> 
                    <div className='flex flex-col text-center'>
                        <span>تاريخ الاستحقاق</span>
                        <span>DUE DATE</span>
                    </div>
                    <span className='font-bold'>{inv_date_due}</span>
                </div> */}
            </div>
            {/* <hr className='mx-2 mt-2'/>   */}
        </div>
    )
}

export default InvoiceHeader