import {useState,useEffect} from 'react'
// import { GrView } from 'react-icons/gr';
// import { FiEdit } from 'react-icons/fi';
// import { RiDeleteBinLine } from 'react-icons/ri';
import SearchCustomer from '../SearchCustomer';
import ListCustomersItem from './ListCustomersItem';
import NoDataFound from '../ui/NoDataFound';
import { ConfirmDelete, MwButton, MwSpinner } from '../ui';
import {MdOutlineAddBox} from 'react-icons/md'
import CardHeadeWithActions from '../ui/CardHeadeWithActions';
import { useCurrent, useDelete, useFetch } from '../../hooks';

const ListCustomers = ({setMode,idItemSelected,setIdItemSelected}) => {
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const { currentTenantId ,currentCompanyId,currentBranchId} = useCurrent();
    // const [customers,setCustomers] = useState(); 
    const {data:deleteResult,deleteItem} = useDelete()
    const {data:dataCustomers,loading,refreshHandler} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const onChangeSearch = (newValue) => {
        //setData(newValue);
    }

    const editHandeler = (id) =>{
        setIdItemSelected(id)
        setMode('Edit')
    }
    const deleteHandeler = (id) =>{
        setIdItemSelected(id)
        setShowConfirmDelete(true);
    }

    const confirmDelete = () =>{
        deleteItem(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/delete/${idItemSelected}`)
    }
    
    useEffect(()=>{
        if(deleteResult){
            console.log('deleteResult',deleteResult)
            refreshHandler()
            setShowConfirmDelete(false);
        }
    },[deleteResult]);

    return (
        <>
            <ConfirmDelete onDeleteHandler={confirmDelete} onShow={showConfirmDelete} setOnShow={setShowConfirmDelete} msg={`هل انت متاكد من حذف العميل`} />
            <div className=''>
                <CardHeadeWithActions title={`قائمة العملاء`}>
                    <SearchCustomer className={`bg-white`} isOpen={false} onChangeSearch={onChangeSearch} />
                    <MwButton inGroup={true} type='saveBtn' onClick={()=>setMode('Add')}  >
                        <MdOutlineAddBox size={18}/>
                        أضف عميل جديد
                    </MwButton>
                </CardHeadeWithActions>
                {loading && <MwSpinner/>}
                {
                    !loading && dataCustomers && dataCustomers.data.length > 0 ? 
                        <div className='pt-3'>{
                            dataCustomers.data.map((item) =>(<ListCustomersItem key={item._id} item={item} deleteHandeler = {deleteHandeler} editHandeler = {editHandeler} /> ))
                            }</div> :
                        <NoDataFound msg={`لا يوجد عملاء `}/>
                }
            </div>
        </>
    )
}

export default ListCustomers