import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';
import {Doughnut } from 'react-chartjs-2'
import { MwSpinnerButton } from '../ui';
import CardHeader from '../card/CardHeader';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
)

const PieChart = ({label,labels,datasetsLabel,datasetsData,borderColor,backgroundColor,className,loading}) => {
    const data = {
        labels: labels,
        datasets:[
            {
                label:datasetsLabel ,
                data:datasetsData,
                borderColor: borderColor,
                backgroundColor: backgroundColor,
                tension:0.4,
                
                // circumference:180,
                // rotation:270
            }
        ]
    }
    const options = {
        plugins: {
            // defaultFontFamily:'"Tajawal", sans-serif',
            legend: {
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 14,
                        family: '"Tajawal", sans-serif',
                    }
                }
            }
        }
    }

    return ( 
        <>
        <div className='flex-1 rounded-3xl bg-slate-50 py-3 flex flex-col items-center justify-center  md:w-fit border-2  border-slate-50'>
        { loading && <MwSpinnerButton withLabel={false} isFullCenter={true} />}
        { datasetsData.length > 0 && 
            <div>
                { label && <CardHeader>{label}</CardHeader> }
                
                <Doughnut 
                    className={` ${className}`}
                    data={data}
                    options={options}
                >
                </Doughnut>
            </div>
        }
            
        </div> 
        </>
    )
}

export default PieChart