import React, { useEffect } from 'react'
import {  useNavigate, useParams } from 'react-router-dom'
import parse from 'html-react-parser';
import InvListBasicZatcaItem from './InvListBasicZatcaItem';
import {  MwSpinnerButton, NoDataFound } from '../../ui'
import { useInvoice, useZatca } from '../../../hooks';

const InvListBasicZatca = ({
    data,
    loading=true,
    links=[],
    refreshHandler = ()=>{},
    paginateHandler = (url)=>{} ,
    withBorder=true,
    withSummary=false,
}) => {
    const params = useParams();
    const navigate = useNavigate();
    const {formatter} = useInvoice()
    const {zatcaRespose,storeResult,loading:zatcaLoading,zatcaInvId,sendToZatca} = useZatca();

    useEffect(() => {
        console.log('storeResult => ',storeResult,'zatcaRespose => ',zatcaRespose)
        refreshHandler()
    },[storeResult])

    return (
        <>

            {!loading ? 
            <div className={`flex-1 ${ withBorder && ' border rounded-xl ' }` }>
                { data?.length > 0 ? <ul>
                    <li className={`flex gap-2 justify-between p-3 text-xs border-b bg-slate-100 `}>
                        <div className='w-16  text-center'>كود الفرع</div>
                        <div className='w-24  text-center'>رقم الفاتورة</div>
                        <div className='flex-1'>اسم العميل</div>
                        <div className='w-24   text-center'>مبلغ الفاتورة</div>
                        { !withSummary && <div className='w-32 px-1  text-center'>تاريخ الفاتورة</div> }
                        { <div className='w-36 px-1  text-center'> نوع الفاتورة Zatca </div>}
                        { <div className='w-24 px-1  text-center'> حالة Zatca </div>}
                        { <div className='flex-1 px-1  text-center'>  اجراءات Zatca </div>}
                    </li>
                    <div className={ ` ${ !withSummary && 'h-[calc(100vh-350px)]'} overflow-auto pb-3`}>
                        {data.map((item,index) => (
                            <InvListBasicZatcaItem 
                                key={index}
                                item = {item}
                                index = {index}
                                withSummary = {withSummary}
                                formatter = {formatter}
                                dataLength = {data?.length}
                                zatcaLoading = {zatcaLoading}
                                zatcaInvId = {zatcaInvId}
                                sendToZatca = {sendToZatca}
                                />))}
                    </div>
                    { 
                        links.length > 0 &&
                            <div id="dataLinks" className="list-none  flex gap-3 border-t pt-3 px-5 cursor-default">
                            {
                                links.length > 3 && 
                                links.map((item,i) => <li key={i} className='hover:font-bold' onClick={()=>item?.url && paginateHandler(item?.url.replace('http','https'))} >{ parse(item.label)}</li>)
                            }
                            </div> 
                    }
                </ul> : <NoDataFound msg='لا توجد فواتير ' />  }
            </div> : <div className='flex-1'>
                        <MwSpinnerButton withLabel={false} isFullCenter={true} />
                    </div> }
        </>
    )
}

export default InvListBasicZatca