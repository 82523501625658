import React from 'react'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';

const InvoiceFooter = ({
    totalAmount,
    vatAmount,
    discountAmount,
    netAmount,
    invTerms,
    SETTING_PRICE_INCLUDE_VAT,
    SETTING_INV_VAT_PERCENT,
    SETTING_SHOW_TERMS_ON_PRINT,
    isDelivery,
    currentLang,
    SETTING_INV_CURRENCY,
    formatter,
    invNotes
}) => {

    const [t] = useTranslation('global')
    console.log('SETTING_PRICE_INCLUDE_VAT',SETTING_PRICE_INCLUDE_VAT)
    return (
        <>

        { !isDelivery &&
        <div> 
            <div className='my-3 border rounded text-xs'>
                
                {
                    discountAmount ? 
                    <div className='flex-col'>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div className='flex flex-col'>
                                <span>الإجمالي قبل الخصم </span>
                                <span>TOTAL BEFORE DISCOUNT</span>
                            </div>
                            <div className='flex flex-col justify-center '>{ formatter.format(totalAmount)  } </div>
                        </div>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div className='flex flex-col'>
                                <span> الخصم </span>
                                <span>DISCOUNT</span>
                            </div>
                            <div className='flex flex-col justify-center '>{formatter.format(discountAmount)}</div>
                        </div>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div>{t('invoice.TOTAL_AFTER_DISCOUNT')}</div>
                            <div className=''>{formatter.format(totalAmount - discountAmount )} </div>
                        </div>
                    </div> : 
                    <div className='flex justify-between px-3 py-2 border-b'>
                        <div className='flex flex-col'>
                            <span>الإجمالي الفرعي </span>
                            <span>SUB TOTAL</span>
                        </div>
                        <div className='flex flex-col justify-center '>{formatter.format(totalAmount)}  </div>
                    </div>
                }
                <div className='flex justify-between px-3 py-2 border-b'>
                    <div className='flex flex-col'>
                            <span> ضريبة القيمة المضافة ({SETTING_INV_VAT_PERCENT}%)</span>
                            <span>VAT ({SETTING_INV_VAT_PERCENT}%)</span>
                        </div>
                    <div className='flex flex-col justify-center '>{formatter.format(vatAmount)} </div>
                </div>
                <div className='flex justify-between px-3 py-2 border-b'>
                    <div className='flex flex-col'>
                            <span>الإجمالي شامل الضريبة </span>
                            <span>TOTAL</span>
                        </div>
                    <div className='font-bold flex flex-col justify-center '>{formatter.format(netAmount)}  </div>
                </div>
            </div>
            {/* <div className='pt-2 flex flex-col justify-center items-center'>
                            { SETTING_PRICE_INCLUDE_VAT === 'true' ?
                                <div className='flex justify-center items-center text-xs text-slate-400 '>{t('invoice.priceIncludesVat')}</div> : 
                                <div className='text-xs text-slate-400 text-center'>{t('invoice.priceNotIncludesVat')}</div> 
                                
                            }
                        </div> */}
        </div>}
        {invNotes && <div className='p-2'>{parse(invNotes.replaceAll('\n','<br/>')) }</div>}
        { SETTING_SHOW_TERMS_ON_PRINT === 'true' && invTerms && !isDelivery && <div className='text-xs py-5'>
            <div className='font-bold '>- {t('invoice.ConditionAndTerms')}</div>
            <div className='pt-1 px-2 '>{ parse(invTerms.replaceAll('\n','<br/>')) }</div>
        </div>}
        </>
    )
}

export default InvoiceFooter