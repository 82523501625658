export const PRODUCT_TYPE_LIST = [
    {
        label:'Sales',
        value:'Sales',
        lang:'en'
    },
    {
        label:'مبيعات',
        value:'Sales',
        lang:'ar'
    },
    {
        label:'Purchases',
        value:'Purchases',
        lang:'en'
    },
    {
        label:'مشتريات',
        value:'Purchases',
        lang:'ar'
    },
    {
        label:'Sales And Purchases',
        value:'SalesAndPurchases',
        lang:'en'
    },
    {
        label:'مبيعات ومشتريات',
        value:'SalesAndPurchases',
        lang:'ar'
    },
];

export const allowImgTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
