import React from 'react'
import { useCurrent } from '../../hooks';

const MyBranches = () => {
    const {currentBranches} = useCurrent();
    console.log(currentBranches);
    return (
        <div>MyBranches</div>
    )
}

export default MyBranches