import React from 'react'
import { MwInputText } from '../../ui'

const AdvancedForm = ({
    errors,
    customerAccountNo,
    setCustomerAccountNo,
    customerVatNo,
    setCustomerVatNo,
    limitCredit,
    setLimitCredit
}) => {
    return (
        <>
            <div className='py-2'>
                <MwInputText 
                    label='الرقم الضريبي'
                    id='customerVatNo' 
                    value={customerVatNo} 
                    onChange={(e)=>setCustomerVatNo(e.target.value)} />
            </div>
            <div className='py-2'>
                <MwInputText 
                    label='رقم حساب العميل'
                    id='customerAccountNo' 
                    value={customerAccountNo} 
                    onChange={(e)=>setCustomerAccountNo(e.target.value)} />
            </div>
            <div className='py-2'>
                <MwInputText 
                    label='الحد الائتماني'
                    id='limitCredit' 
                    inputType='Number'
                    value={limitCredit} 
                    invalid={!!errors.limitCredit}
                    invalidMsg={errors.limitCredit}
                    onChange={(e)=>setLimitCredit(e.target.value)} />
            </div>
        </>
    )
}

export default AdvancedForm