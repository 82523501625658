import React,{useEffect,useState} from 'react'
import {BiChevronDown} from 'react-icons/bi'
import {AiOutlineSearch} from 'react-icons/ai'
import {MdAddBox} from 'react-icons/md'
import axios from 'axios';
import {  useTranslation } from 'react-i18next';
import { useUser, usePost } from '../../hooks';
import {LangArrayToObjKeyValue} from '../../utils/global';
import { useCurrent } from '../../hooks';
import MwInputText from './MwInputText';


const MwSelectorProduct = ({onChangeCallback,onCreateProduct,reloadProductList,withLabel=false,invalid=false,initalValue,disabled=false}) => {
    const [t] = useTranslation('global')
    const {currentLangId} = useCurrent();
    const {getUser} = useUser();
    const [products,setProducts] = useState([]);
    const [listLength,setListLength] = useState(0);
    const [inputValue,setInputValue] = useState('');
    const [selectedItem,setSelectedItem] = useState();
    const [open,setOpen] = useState(false);
    const {data:resultPost,loading,postData} = usePost();
    
    const isProductsUpdated = () => {
        if(localStorage.getItem('isProductsUpdated')){
            return JSON.parse(localStorage.getItem('isProductsUpdated'))
            // return true;
        }else{
            return false;
        }
    }
    useEffect(() => {
        if(selectedItem){
            onChangeCallback(selectedItem);
        }
    },[selectedItem]);

    

    useEffect(() =>{

        // if(products.length === 0 || reloadProductList){
        //     postData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/search/All`,{
        //         tenantId: getUser?.tenant_id,
        //         companyId: getUser?.company_id
        //     });
        // }

        const productStorage = localStorage.getItem('products');
        console.log('isProductsUpdated',isProductsUpdated());
        //if((productStorage && !reloadProductList) || !isProductsUpdated()) {
        if(!isProductsUpdated()) {
            setProducts(JSON.parse(productStorage))
            
        } 
        if(isProductsUpdated() || products.length === 0 || reloadProductList){
            postData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/search/All`,{
                tenantId: getUser?.tenant_id,
                companyId: getUser?.company_id,
                productType: 'Sales'
            });
        }
    },[reloadProductList]);

    useEffect(()=>{
        if(resultPost && !loading){
            setProducts(resultPost.data)
            localStorage.setItem('products', JSON.stringify(resultPost.data));
            localStorage.setItem('isProductsUpdated','false')
        }
    },[resultPost,loading]);

    useEffect(()=>{
        if(inputValue){
            const _list = products?.filter((product)=>{
                const productName = LangArrayToObjKeyValue(product?.productName)?.[currentLangId] || product?.productName[0]?.text || product?.productName[1]?.text
                if(productName?.toLowerCase().startsWith(inputValue.toLowerCase())){
                    return true;
                }
                return false;

            });            
            setListLength(_list.length);
            
        }
    },[inputValue]);

    const productList = products?.map((product)=>{
        // const productFilterLang = product?.productName?.filter(productFilter => productFilter.lang ===langSelected.value)[0]
        // const productFilterLangDefault = product?.productName?.filter(productFilter => productFilter)[0]
        // const productName = productFilterLang?.text  || productFilterLangDefault?.text
        const productName = LangArrayToObjKeyValue(product?.productName)?.[currentLangId] || product?.productName[0]?.text || product?.productName[1]?.text
       
        return <li 
            key={product._id}
            onClick={()=>{
                setSelectedItem(product)
                setOpen(false);
                setInputValue("")
            }}
            className={`ps-5 py-1 border-b text-sm hover:bg-sky-600 hover:text-white ${productName?.toLowerCase().startsWith(inputValue.toLowerCase()) ? 'block' :'hidden'}`}> 
            {productName}
        </li>
});

    const addProductHandler = () => {
        setOpen(false);
        onCreateProduct(true);
    }

    return (
        <>
        <div className='relative'>
            
            { withLabel && <label className={`text-xs text-slate-500 `} >{t('invoice.selectProduct')}</label> }
            <div 
                onClick={()=> {
                    !disabled && setOpen(!open);
                    setListLength(1);
                }}
                className={` h-9 bg-white w-full border flex items-center text-sm  justify-between rounded ${ !!invalid && 'border border-red-400' } ${disabled ? 'text-slate-200' : 'text-slate-300'} `}
                >
                {
                selectedItem ? 
                    <span className={` px-2 ${disabled ? ' text-slate-300' : 'text-slate-500'}`}>{LangArrayToObjKeyValue(selectedItem.productName)?.[currentLangId] || selectedItem.productName[0]?.text || selectedItem.productName[1]?.text}</span> : 
                    // initalValue ? initalValue : t('invoice.selectProduct')
                    <MwInputText 
                        disabled={disabled}
                        value={initalValue}
                        classNameInput =  'text-start px-3 border-0 font-bold'
                        onChange={(e)=>{
                            setInputValue(e.target.value);
                            onChangeCallback(e.target.value);
                        }
                        }
                    />
                } 
                <BiChevronDown size={20} className='border rounded m-2'/>
            </div>
            {listLength > 0 && <ul className={` bg-slate-200 z-50  overflow-y-auto absolute w-screen  md:w-full pe-12 md:pe-0  rounded-b-lg  ${open ? 'max-h-60 block' : 'max-h-0 hidden'}`}>
                <div className='sticky top-0 p-3'>

                    <div className='text-end'>
                        <div className=' text-sky-700 absolute top-6 end-5'  onClick={addProductHandler} >
                        <MdAddBox size={24}/> 
                        </div>
                    </div>
                    <div className='flex items-center justify-between  px-2  bg-white rounded '>
                        <AiOutlineSearch size={18} className='text-gray-700' />
                        <input 
                            type="text"
                            placeholder='Enter Product Name'
                            className='placeholder:text-grray-700 p-2 outline-none p-3 w-full text-sm bg-white'
                            value={inputValue}
                            onChange={(e)=>setInputValue(e.target.value.toLowerCase())}
                            />
                    </div>
                </div>
                <div>
                    {productList}
                </div>
            </ul>}
        </div>
        </>
    )
}

export default MwSelectorProduct