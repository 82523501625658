import React from 'react'
import { MwInputText,MwSwitch } from '../../ui'

const StockAndPrice = ({
    productCode,
    setProductCode,
    productPrice,
    setProductPrice,
    productBarcode,
    setProductBarcode,
    productFactoryCode,
    setProductFactoryCode,
    isStock,
    setIsStock,
    codeInStock,
    setCodeInStock,
    qty,
    setQty,
    cost,
    setCost,
    weightProduct,
    setWeightProduct,
    sizeProduct,
    setSizeProduct,
    errors
}) => {
    return (
        <div className='p-3'>
            <div className=' '>
                <MwInputText
                    label='كود المنتج'
                    id='productCode' 
                    value={productCode} 
                    onChange={(e)=>setProductCode(e.target.value)}
                />
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='سعر المنتج'
                    id='productPrice' 
                    invalid = {!!errors[`price`]}
                    invalidMsg = {errors[`price`]}
                    value={productPrice} 
                    onChange={(e)=>setProductPrice(e.target.value)}
                />
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='تكلفة المنتج'
                    id='cost' 
                    invalid = {!!errors[`cost`]}
                    invalidMsg = {errors[`cost`]}
                    value={cost} 
                    onChange={(e)=>setCost(e.target.value)}
                />
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='باركود المنتج'
                    id='productBarcode' 
                    value={productBarcode} 
                    onChange={(e)=>setProductBarcode(e.target.value)}
                />
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='كود المصنع'
                    id='productFactoryCode' 
                    value={productFactoryCode} 
                    onChange={(e)=>setProductFactoryCode(e.target.value)}
                />
            </div>
            <div className='flex justify-between items-center py-4  border-b mb-2'>
                    <label className='text-xs text-slate-400'> يستخدم المستودع / لا يستخدم المستودع</label>
                    <MwSwitch custKey='isStock' isActive={isStock} setIsActive={setIsStock} onChangeSwitch={() => setIsStock(!isStock)} />
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='كود المستودع'
                    id='codeInStock' 
                    value={codeInStock} 
                    onChange={(e)=>setCodeInStock(e.target.value)}
                />
            </div>
            <div className=' my-2'>
                <MwInputText
                    inputType='Number'
                    label='الكمية'
                    id='qty' 
                    value={qty} 
                    onChange={(e)=>setQty(e.target.value)}
                />
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='الوزن'
                    id='weightProduct' 
                    value={weightProduct} 
                    onChange={(e)=>setWeightProduct(e.target.value)}
                />
            </div>
            <div className=' my-2'>
                <MwInputText
                    label='الحجم'
                    id='sizeProduct' 
                    value={sizeProduct} 
                    onChange={(e)=>setSizeProduct(e.target.value)}
                />
            </div>
        </div>
    )
}

export default StockAndPrice