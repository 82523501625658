import React, { useState,useEffect } from 'react'
import { RiDeleteBin5Line } from "react-icons/ri";

import {MwButton, MwInputFile} from '../../../components/ui'

const ProductImages = ({
    productHomePic,
    productHomePicDefault,
    loadingUploadHomePic,
    handleHomePicUpload,
    productImages,
    setProductImages,
    loadingUploadProductImages,
    handleImagesItemUpload,
    resultdelete,
    loadingDeleting,
    handleDelete

}) => {
    const  [_picProduct,_setPicProduct] = useState(productHomePic || productHomePicDefault) ;
    const  _picProductKey = _picProduct?.split('/')[3]+'/'+_picProduct?.split('/')[4];
    

    const _deleteHandler = (key,image) => {
        handleDelete(key)
        if(image === 'homePic'){
            _setPicProduct('')
        }else{
            setProductImages(productImages.filter(img => img !== image))
        }
    }

    useEffect(() =>{
        if(productHomePic){
            _setPicProduct(productHomePic)
        }
        if(productHomePicDefault){
            console.log('pic',_picProduct,_picProductKey);
            _setPicProduct(productHomePicDefault)
        }
    },[productHomePicDefault,productHomePic])


    return (
        <div className='flex flex-col gap-5 bg-white p-5 rounded-xl'>
            <div className='flex justify-between gap-5 '>
                <MwInputFile 
                    label={'صورة المنتج الرئيسية : '}
                    loading={loadingUploadHomePic} 
                    onChangeHandler={handleHomePicUpload}
                    /> 
                
                { _picProduct && <div className='flex flex-col gap-2 items-center justify-center'>
                        <img 
                        src={ _picProduct } 
                        title='Product '
                        alt='Product '
                        className='w-32  mx-3'  
                        />
                        <MwButton actionType='button' size='sm' type='secondary' onClick={()=>_deleteHandler(_picProductKey,'homePic')}> <RiDeleteBin5Line /> </MwButton>
                    </div>}
            </div> 
            <hr/>
            <div className='flex flex-col gap-1'>
                <MwInputFile 
                    isMultiple = {true}
                    label={'صور المنتج  : '}
                    loading={loadingUploadProductImages} 
                    onChangeHandler={handleImagesItemUpload}
                    /> 
                <div className='grid grid-cols-4 justify-between items-center gap-5 p-5 '>
                    {productImages?.map((item,index) => <div className='flex flex-col gap-2 items-center justify-center'>
                        <img 
                        src={ item } 
                        title='Product '
                        alt='Product '
                        className='' 
                        key={index}
                        />
                        <MwButton actionType='button' size='sm' type='secondary' onClick={()=>_deleteHandler(item?.split('/')[3]+item?.split('/')[4],item)}> <RiDeleteBin5Line /> </MwButton>
                        </div> )}
                </div>
                
                
            </div> 
        </div>
    )
}

export default ProductImages