import React from 'react'
import MwSpinnerButton from './MwSpinnerButton'

const MwInputFile = ({
    onChangeHandler=()=>{} ,
    loading=false, 
    acceptTypes="image/*",
    label,
    id='file_input',
    isMultiple = false,
    isSmall = false
}) => {
    return (
        <div className={`flex flex-row items-center ${ isSmall ? 'p-0' : 'p-2' }  `}>
            {label && <label className=" py-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor={id}>{label}</label>}
            <input type="file" 
                id={id}
                className={ ` w-40 text-gray-800 text-sm 
                file:text-sm file:border-0 
                file:rounded-full file:font-semibold file:text-violet-700 file:bg-violet-50 
                hover:file:bg-amber-100 hover:file:cursor-pointer ${ isSmall ? '' : 'flex-1  file:mx-6 file:px-4 file:py-2' }`}
                accept={acceptTypes} 
                onChange={onChangeHandler}  
                multiple={isMultiple}
                />
            { loading && <MwSpinnerButton withLabel={false}/>}
        </div>
    )
}

export default MwInputFile