import { useEffect, useState } from "react";
import useInvoice from "./useInvoice"
import usePost from "./usePost";
import useCurrent from "./useCurrent";

const useZatca = () => {
    const [loading,setLoading] = useState(false);
    const [zatcaRespose,setZatcaResponse] = useState();
    const {SETTING_INVOICE} = useInvoice();
    const {currentTenantId,currentCompanyId,currentBranchId,userId,currentCompany} = useCurrent();
    const {data:dataResult,loading:loadingResult,postData} = usePost();
    const _url =  `${SETTING_INVOICE?.ZATCA_LINK}?env=development&invoice_type=simplified&zatca_type=reporting`;
    const [zatcaInvId,setZatcaInvId] = useState();
    const [inv,setInv] = useState();

    const {data:storeResult,loading:loadingStore,postData:storeInvMasterZatca} = usePost();

    const sendToZatca = (invoice,invId) => {

        if(!SETTING_INVOICE?.ZATCA_LINK){
            console.log("No ZATCA link provided")
            return false;
        }
        setLoading(true)
        setInv(invoice)
        setZatcaInvId(invId)
        console.log('invoice',currentCompany,invoice)
        const address = (currentCompany?.mainAddress[0]?.text).split(',');

        const _data = {
            "invoice_date": invoice?.inv_date.substring(0,10) ,
            "invoice_time" : invoice?.inv_date.substring(10).trim() ,
            "seller_name": currentCompany?.name[0].text,
            "seller_vat": currentCompany?.vatNumber,
            "seller_street": address[1] || 'Olya street',
            "seller_building": "1111",
            "postal_zone": "11417",
            "seller_city_name": address[0] || 'Al Riyadh',
            "seller_country_code": "SA",
            "invoice_number": invoice?.inv_no,
            "invoice_amount": invoice?.total_amount,
            "tax_amount": invoice?.vat_amount,
            "currency_code": "SAR",
            "buyer_name": invoice?.zatca?.buyer_name || invoice?.customer_name || "",
            "buyer_vat": invoice?.zatca?.buyer_vat_no || "",
            "buyer_street": invoice?.zatca?.buyer_street || "Riyadh street",
            "buyer_building": invoice?.zatca?.buyer_building || "",
            "buyer_city_name": invoice?.zatca?.buyer_city || "",
            "buyer_postal_code": invoice?.zatca?.buyer_postal || "1267",
            "buyer_country_code": "SA",
            "c_code": invoice?.zatca?.buyer_postal || "",
            "line_items": invoice?.inv_details?.map((item)=>({
                "id": item.id,
                "unit_code": "1",
                "description": item.product_name || null,
                "product": item.product_name || null,
                "quantity": item?.qty || 1,
                "price": item.price,
                "total": item.total_price,
                // "total": item.product_net_total_with_vat,
                "price_after_discount": item.product_net_total,
                "tax_amount": item?.product_vat,
                "classified_tax_category": parseInt(item?.product_vat) === 0 ? "Z" : "S",
                "classified_tax_percent": parseInt(item?.product_vat) === 0 ? "0.00" : "15.00",
                "item_discount": item.product_discount || "0.00"
            }))

            } 
            console.log('_data',_data)
        postData(_url,_data);
    }
    const storZatca = (zatcaResponse) => { 
        const _data = {
            "inv_id" : inv.id,
            "inv_no" : inv.inv_no,
            "branch_code" : inv.zatca?.branch_code,
            "zatca_inv_type" : inv?.zatca?.zatca_inv_type || "simplified", // simplified,simplified_debit_note,simplified_credit_note,'standard,standard_debit_note,standard_credit_note
            "zatca_status": zatcaResponse?.reporting?.reportingStatus || zatcaResponse?.reporting?.clearanceStatus, //  CLEARED,PASS,REPORTED,REJECTED
            "zatca_status_final": zatcaResponse?.reporting?.validationResults?.status?.trim() , //  CLEARED,PASS,REPORTED,REJECTED
            "zatca_qr" : zatcaResponse?.qrcode || null,
            "clearedInvoice" : zatcaResponse?.reporting?.clearedInvoice || null,
            "zatca_response" : JSON.stringify(zatcaResponse),
            "notes" : "",
            "tenant_id" : currentTenantId || null,
            "company_id" : currentCompanyId || null,
            "branch_id" : currentBranchId || null,
            "created_by" : userId || 0
        }
        storeInvMasterZatca(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/Zatca`,_data);
    }
    useEffect(()=>{
        if(dataResult){
            setTimeout(()=>{
                storZatca(dataResult)

            },3000);
            setZatcaResponse(dataResult)
        }
    },[dataResult]);

    useEffect(()=>{
        if(storeResult){
            setLoading(false)
        }
    },[storeResult]);

    return {zatcaRespose,storeResult,loading,zatcaInvId,sendToZatca}
}
export default useZatca